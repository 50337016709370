.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-family: 'Gothic';
  letter-spacing: 5px;
  font-weight:100;
  color: red;
  margin: 0px;
}


.sign-in {
  border: 0px;
}

.sign-in:hover {
  background: rgb(105, 255, 255) !important
}
span {
  font-size: 30px;
  padding: 20px;
  color: grey;
  
  font-family: 'Gothic';
  font-weight:100;
  letter-spacing: 5px;
}

span:hover {
  cursor: pointer;
  border-bottom: 1px solid aqua;
}


.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates two columns */
  gap: 10px; /* Space between grid items */
}

.box {
  background-color: white; /* Light grey background */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgrey;
  aspect-ratio: 1; /* Keeps the boxes square */
  overflow: hidden; /* Ensures content doesn't overflow */
  cursor: pointer;
  width: 400px; /* Ensures the image covers the box */
  height: 400px;
}

.box img {
  width: 400px; /* Ensures the image covers the box */
  height: 400px;
  object-fit: cover; /* Ensures the image aspect ratio is maintained */
}

.add-new {
  border: 2px dashed lightgrey; /* Dashed border for the last box */
}

.plus-sign {
  font-size: 24px; /* Size of the plus sign */
  color: grey; /* Color of the plus sign */
}


.mint-btn {
  background: orange;
  color: white;
  padding: 10px;
  border: 0px;
  font-size: 30px;
  font-family: 'Gothic';
  font-weight:100;
  letter-spacing: 5px;
  cursor: pointer;
  width: 70px;
}

.sim-btn:hover {
  background: rgb(255, 80, 80);
}
.sim-btn {
  background: red;
  color: white;
  margin-top: -10px;
  padding: 10px;
  border: 0px;
  font-size: 30px;
  font-family: 'Gothic';
  font-weight:100;
  letter-spacing: 5px;
  cursor: pointer;
  width: 70px;
  padding-left: 14px;
  text-align: center;


}

.user-btn {
  background: white;
  color: black;
  border: 1px solid black;
  padding: 10px;
  font-size: 30px;
  font-family: 'Gothic';
  font-weight: 100;
  letter-spacing: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px; /* Adjust based on your design needs */
}

.user-btn:hover {
  border: 1px solid lightgrey;
}

.download-btn {
  font-family: 'Gothic';
  font-weight:100;
  font-size: 30px;
  letter-spacing: 5px;
  background: black;
  color: grey;
  padding: 10px;
  border: 0px;
  cursor: pointer;

}

.download-btn:hover {
  background: rgb(94, 94, 94);
}

.mint-btn:hover {
  background: rgb(255, 184, 51) !important;
}

.choose-file {
  margin: 10px;
  font-family: 'Gothic';
  font-weight:100;
  font-size: 30px;
  letter-spacing: 5px;
  color: grey;
  padding: 10px;
  border: 0px;
  cursor: pointer;
}

/* .react-apple-signin-auth-btn.react-apple-signin-auth-btn-dark{
  background: transparent;
    width: 150px;
    height: 50px;
    position: absolute;
    border: 0px;
    color: transparent;
} */
svg {
  display: none;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey border */
  border-top: 4px solid #3498db; /* Blue border */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}